h1.conversationHeader {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 64pt;
}

h1.public-card-header {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 48px;
    color: #000000;
}
h1.private-card-header {
    font-family: 'Roboto';
    font-weight: 900;
    font-size: 48px;
    color: #444444;
}

.ant-card.conversation-summary {
    margin-bottom: 40px;
    font-family: 'Roboto';
    font-weight: 900;
    word-wrap: 'break-word';
}
.public-card.ant-card {
    background-color: #ffffff;
    color: #000000;
}
.private-card.ant-card {
    background-color: #e2e2e2;
    color: #343434;
}
.featured-body {
    text-align: left;
    word-wrap: break-word;
}
.status-badge {
    float: right;
    font-size: 35px;
}
