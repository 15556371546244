.ant-card-wider-padding .ant-card-body {
  padding: 17px 32px;
}

.system_message {
  background-color: #eeeeee;
  padding: 6pt;
  color: rgba(193, 193, 193, 0.85);
}

.branch_message {
  border-right-style: inset;
  border-right-color: rgba(238, 68, 35);
  border-right-width: 4px;
}

.embedded_message {
  background-color: #ffeeee;
}
.highlight {
  position: "relative";
  font-size: 16px;
  opacity: 0.2;
}

.highlightPadding {
  padding-right: 14px;
  font-size: 16px;
  opacity: 0.2;
}

.highlightPadding,
.highlight:hover {
  opacity: 1;
}

.embedded_message span.username {
  color: #939393;
}

span.username {
  font-family: "Roboto-Medium";
  font-size: 24px;
  color: "rgba(68, 68, 68, 0.85)";
  margin-left: 16px;
}
